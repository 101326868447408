@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.mentor-tile[data-v-4272af4d] {
  height: 100%;
  width: 100%;
}
.mentor-tile a[data-v-4272af4d] {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: 320px;
    color: inherit;
    text-decoration: none;
}
@media (max-width: 576px) {
.mentor-tile a[data-v-4272af4d] {
        display: block;
}
.mentor-tile a > *[data-v-4272af4d] {
          max-width: 100vw;
}
}
.mentor-tile a[data-v-4272af4d]:hover {
      -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.mentor-tile a:hover .mentor-tile-header-image img[data-v-4272af4d] {
        opacity: 1;
}
.mentor-tile a:hover .mentor-tile-header-button[data-v-4272af4d] {
        opacity: 1;
        left: 0;
        right: 0;
        line-height: 2em;
}
.mentor-tile-header[data-v-4272af4d] {
    position: relative;
}
@media (max-width: 576px) {
.mentor-tile-header[data-v-4272af4d] {
        height: 50vh;
        margin-bottom: 9.5px;
}
}
.mentor-tile-header-image[data-v-4272af4d] {
      width: 100%;
      height: 100%;
      margin-bottom: 14px;
}
.mentor-tile-header-image img[data-v-4272af4d] {
        -o-object-fit: cover;
           object-fit: cover;
        -o-object-position: center top;
           object-position: center top;
        width: 100%;
        height: 100%;
        background-color: #aca79e;
}
@media (min-width: 768px) {
.mentor-tile-header-image img[data-v-4272af4d] {
            opacity: 0.5;
            -webkit-transition: opacity 0.15s ease-in-out;
            transition: opacity 0.15s ease-in-out;
}
}
.mentor-tile-header-button[data-v-4272af4d] {
      display: inline-block;
      font-size: 19px;
      line-height: 1.2;
      font-weight: inherit;
      color: #000;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      background-color: transparent;
      border: 1px solid #000;
      border-radius: 0;
      padding: 10px 44px 12px 44px;
      line-height: 1.5em;
      padding: 10px;
      padding-right: 36px;
      text-align: left;
      background-color: #f3594c;
      color: #fff;
      border-color: #f3594c;
      position: absolute;
      font-size: 14px;
      right: 14px;
      left: 14px;
      bottom: 14px;
}
.mentor-tile-header-button[data-v-4272af4d]:hover {
        color: #000;
        text-decoration: none;
}
.mentor-tile-header-button[data-v-4272af4d]:focus, .mentor-tile-header-button.focus[data-v-4272af4d] {
        outline: 0;
        color: #f3594c;
        background-color: #fff;
        border-color: #fff;
}
@media (max-width: 768px) {
.mentor-tile-header-button[data-v-4272af4d] {
          right: 0;
          left: 0;
          line-height: 2em;
}
}
@media (min-width: 768px) {
.mentor-tile-header-button[data-v-4272af4d] {
          opacity: 0.5;
          -webkit-transition: left, right, line-height 1.5s ease-in-out;
          transition: left, right, line-height 1.5s ease-in-out;
          -webkit-transition: opacity 0.15s ease-in-out;
          transition: opacity 0.15s ease-in-out;
}
}
.mentor-tile-header-button[data-v-4272af4d]:hover {
        color: #fff;
        font-weight: bold;
}
.mentor-tile-header-button[data-v-4272af4d]:after {
        content: "";
        display: inline-block;
        background-image: url("/img/icons/arrow_button.svg");
        background-repeat: no-repeat;
        width: 1em;
        height: 1em;
        position: absolute;
        right: 1em;
        bottom: 1em;
}
.mentor-tile-body[data-v-4272af4d] {
    height: 100%;
    padding: 0 14px;
    background-color: #fff;
    overflow: hidden;
}
.mentor-tile-body-tags[data-v-4272af4d] {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      overflow: hidden;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      margin-top: 5px;
}
.mentor-tile-body-tag[data-v-4272af4d] {
      border: 1px solid #f3594c;
      color: #f3594c;
      padding: 9.5px 26px;
      margin-right: 7px;
      line-height: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      font-weight: bold;
      font-size: 14px;
}
@media (max-width: 768px) {
.mentor-tile-body-tag[data-v-4272af4d] {
          margin-bottom: 7px;
}
}
.mentor-tile-body-name[data-v-4272af4d] {
      font-weight: 700;
      font-size: 36px;
      color: #f3594c;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0;
}
.mentor-tile-body-title[data-v-4272af4d] {
      color: #414042;
      padding-bottom: 14px;
      font-size: 19px;
      font-weight: 500;
}
.mentor-tile-body-icons[data-v-4272af4d] {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      color: #f3594c;
      margin-left: -10px;
      margin-top: -6px;
}
.mentor-tile-body-icons *[data-v-4272af4d] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        text-align: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
}
.mentor-tile-body-icons a[data-v-4272af4d] {
        color: #f3594c;
}
.mentor-tile-body-icons a[data-v-4272af4d]:hover {
          text-decoration: none;
          color: #888894;
}
.mentor-tile-body-icons a:hover .icon[data-v-4272af4d] {
            color: #f3594c;
            background-color: #888894;
}
.mentor-tile-body-icon[data-v-4272af4d] {
      width: 72px;
}
.mentor-tile-body-icon-image[data-v-4272af4d] {
        border-radius: 50%;
        background-clip: padding-box;
        /* stops bg color from leaking outside the border: */
        background-color: #edeae5;
        width: 36px;
        height: 36px;
        margin-bottom: 4px;
}
.mentor-tile-body-icon-image .phone[data-v-4272af4d] {
          padding: 4px 0 6px 0;
}
.mentor-tile-body-icon-value[data-v-4272af4d], .mentor-tile-body-icon-label[data-v-4272af4d] {
        font-size: 12px;
        line-height: 1.3;
}
.mentor-tile-body-icon-value[data-v-4272af4d] {
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
}
.mentor-tile-profile[data-v-4272af4d] {
    padding: 26px 0;
    text-align: justify;
    padding-top: 8px;
    font-size: 19px;
    line-height: 1.3rem;
    color: #414042;
}
@media (max-width: 576px) {
.mentor-tile-profile[data-v-4272af4d] {
        padding-bottom: 0;
}
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.ask-question-card[data-v-b431e254] {
  position: relative;
  background-color: #c8c9ba;
  color: #f3594c;
  min-height: 260px;
  padding: 0 14px;
}
@media (max-width: 576px) {
.ask-question-card[data-v-b431e254] {
      width: 100vw;
      height: 90vh;
      margin: 0 auto;
}
}
.ask-question-card a[data-v-b431e254] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    color: inherit;
    text-decoration: none;
}
@media (max-width: 576px) {
.ask-question-card a[data-v-b431e254] {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        height: 90%;
        -webkit-box-pack: space-evenly;
            -ms-flex-pack: space-evenly;
                justify-content: space-evenly;
}
}
.ask-question-card a[data-v-b431e254]:hover {
      color: #fff;
}
.ask-question-card a:hover h2[data-v-b431e254] {
        color: #fff;
}
.ask-question-card-header[data-v-b431e254] {
    margin: 0 auto;
    color: #fff;
}
.ask-question-card-header-image[data-v-b431e254] {
      height: 240px;
      text-align: center;
      vertical-align: bottom;
}
@media (max-width: 576px) {
.ask-question-card-header-image[data-v-b431e254] {
          margin-top: 36px;
          height: 160px;
}
}
.ask-question-card-header-image svg[data-v-b431e254] {
        height: 100%;
}
.ask-question-card-body[data-v-b431e254] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    padding: 36px;
}
@media (max-width: 576px) {
.ask-question-card-body[data-v-b431e254] {
        padding: 0 19px 19px 19px;
}
}
.ask-question-card-title h2[data-v-b431e254] {
    font-size: 36px;
    line-height: 1.2;
}
.ask-question-card-content[data-v-b431e254] {
    font-size: 19px;
    line-height: 1.2;
}
.ask-question-card-link[data-v-b431e254] {
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: right;
}
.ask-question-card-link svg[data-v-b431e254] {
      height: 80px;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.mentor-grid[data-v-7436fa8a] {
  display: block;
  padding: 40px 0;
  max-width: 992px;
  margin: 0 auto;
}
.mentor-grid-card[data-v-7436fa8a] {
  margin: 8px 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
@media (max-width: 576px) {
.mentor-grid-card[data-v-7436fa8a] {
      width: 100vw;
      margin-bottom: 40px;
}
}
.mentor-grid-rule[data-v-7436fa8a] {
  margin: 14px;
  border: 0.5px solid #d8d6cd;
}
@media (max-width: 576px) {
.mentor-grid-rule[data-v-7436fa8a] {
      border: 0.5px solid #fff;
}
}
.placeholder[data-v-7436fa8a] {
  content: " ";
  background-color: yellow;
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.usergroup[data-v-73035978] {
  position: relative;
}
.usergroup-header[data-v-73035978] {
    width: 100%;
    height: 600px;
    margin-bottom: -200px;
    background-color: #495161;
    display: block;
}
.usergroup-header img[data-v-73035978] {
      height: 100%;
      width: 100%;
      -o-object-fit: cover;
         object-fit: cover;
}
@media (max-width: 576px) {
.usergroup-header[data-v-73035978] {
        display: none;
}
}
.usergroup-header-mobile[data-v-73035978] {
      display: none;
}
@media (max-width: 576px) {
.usergroup-header-mobile[data-v-73035978] {
          display: block;
          width: 100vw;
}
}
.usergroup-header-mobile img[data-v-73035978] {
        height: 100%;
        width: 100%;
        -o-object-fit: contain;
           object-fit: contain;
}
.usergroup-body[data-v-73035978] {
    position: relative;
    width: 100%;
    max-width: 992px !important;
    padding: 0 132px;
    background-color: #fff;
    margin: 0 auto;
}
@media (max-width: 576px) {
.usergroup-body[data-v-73035978] {
        max-width: 100vw;
        width: 100vw;
        padding: 0 14px;
}
}
.usergroup-body-title[data-v-73035978] {
      padding-top: 36px;
      text-align: center;
      line-height: 1;
      color: #f3594c;
      font-size: 44px;
      font-weight: 700;
}
@media (max-width: 576px) {
.usergroup-body-title[data-v-73035978] {
          line-height: 1.2;
}
}
.usergroup-body-intro[data-v-73035978] {
      margin-top: 26px;
      font-weight: 300;
      font-size: 26px;
      color: #414042;
      line-height: 1.3;
      text-align: center;
      padding: 26px 44px;
}
@media (max-width: 576px) {
.usergroup-body-intro[data-v-73035978] {
          padding: 14px 14px;
          font-size: 19px;
          line-height: 1.4;
}
}


/*# sourceMappingURL=usergroups.e36518a6.css.map*/